import React from 'react';
import { connect } from 'react-redux';
import PrivacyPolicyPopup from '../Appointment/TermsAndConditions/PrivacyPolicyPopup';
import {setBrandText, setBrandLink, setPreferredBrand} from '../MyRewards/MyRewards';
import { CONFIRMATION, terms, notApplicable, REVIEW } from '../../shared/Constants/SharedConstants';
import { AuthenticatedFlow, EditAppointmentFlow } from '../../shared/RoutingFlow/FlowComponentsConstants';

class Disclaimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPrivacyPopup: false,
        };
    }

    togglePrivacyPopup() {
        this.setState({ showPrivacyPopup: !this.state.showPrivacyPopup });
        window.scrollTo(0, 0);
    }

    consent = () => {
        const showPrivacyDetails = this.props.countryOptions.SHOW_PRIVACY_DETAILS;
        const showPrivacyDetailsModal = this.props.countryOptions.SHOW_PRIVACY_DETAILS_MODAL;
        if (showPrivacyDetails === 'true' && showPrivacyDetailsModal === 'true') {
            return (
                <div className="view-privacy-policy">
                    <span
                        id="btnPrivacy"
                        className="contact-disclaimer-label"
                        dangerouslySetInnerHTML={{
                            __html: `${this.props.vTranslations.CONSENT_PART_ONE} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`,
                        }}
                    />
                </div>
            );
        }
        if (showPrivacyDetails === 'true' && showPrivacyDetailsModal === 'false') {
            return (
                <span
                    id="btnPrivacy"
                    className="contact-disclaimer-label"
                    dangerouslySetInnerHTML={{
                        __html:
                            this.props.vTranslations.CONTACT_CONSENT === undefined
                                ? `${this.props.vTranslations.CONSENT_PART_ONE} ${this.props.vTranslations.CONSENT_LINK} ${this.props.vTranslations.CONSENT_PART_TWO}`
                                : this.props.vTranslations.CONTACT_CONSENT,
                    }}
                />
            );
        }
        return null;
    };
    render() {
        let { vTranslations, preferredBrand, themeMake, make, brands, countryOptions } = this.props;
        let brand = setPreferredBrand(preferredBrand, themeMake, make, brands);
        const brandText = setBrandText(vTranslations, brand, themeMake, make, brands);
        const brandLink = setBrandLink(brand, themeMake, make, brands) + '/' + terms;
        brand = brand ? brand : "GM";
        let brandUrl = '';
        if (countryOptions.GET_LOYALTY === 'true') {
            switch (brandText) {
                case vTranslations.MY_CHEVROLET_REWARDS:
                    brandUrl = (
                        <a href={brandLink} target="_blank">
                            {brandText.toString().replace(/ /g, '').concat('.com')}{' '}
                        </a>
                    );
                    break;
                case vTranslations.MY_BUICK_REWARDS:
                    brandUrl = (
                        <a href={brandLink} target="_blank">
                            {brandText.toString().replace(/ /g, '').concat('.com')}{' '}
                        </a>
                    );
                    break;
                case vTranslations.MY_GMC_REWARDS:
                    brandUrl = (
                        <a href={brandLink} target="_blank">
                            {brandText.toString().replace(/ /g, '').concat('.com')}{' '}
                        </a>
                    );
                    break;
                case vTranslations.MY_CADILLAC_REWARDS:
                    brandUrl = (
                        <a href={brandLink} target="_blank">
                            {brandText.toString().replace(/ /g, '').concat('.com')}{' '}
                        </a>
                    );
                    break;
                default:
                    brandUrl = (
                        <a href={brandLink} target="_blank">
                            {brandText.toString().replace(/ /g, '').concat('.com')}{' '}
                        </a>
                    );
                    break;
            }
        }
        if (
            (this.props.countryOptions.SHOW_PRIVACY_DETAILS === 'true' && this.props.headerNav === REVIEW) ||
            countryOptions.GET_LOYALTY === 'true'
        ) {
            return (
                <div className="submit-appointment-disclaimer paper">
                    <div className="submit-appointment-disclaimer-title headline3"></div>
                    <div className="submit-appointment-disclaimer-statement">
                        {this.props.headerNav === REVIEW && this.props.country !== 'US' && this.consent()}
                        {countryOptions.GET_LOYALTY === 'true' && (
                            <>
                                {countryOptions.GET_LOYALTY_CARD_FOOTER === 'true' ? (
                                    <>
                                        {(this.props.active &&
                                            this.props.usePoints &&
                                            this.props.headerNav === CONFIRMATION) ||
                                        (this.props.active &&
                                            this.props.headerNav === REVIEW &&
                                            this.props.useMyRewardsIndicator !== notApplicable) ||
                                        (this.props.flowSource === EditAppointmentFlow &&
                                            this.props.useMyRewardsIndicator !== notApplicable) ||
                                        (this.props.headerNav === CONFIRMATION &&
                                            this.props.flowSource === AuthenticatedFlow &&
                                            this.props.active === undefined &&
                                            this.props.enrollMyRewardsError === undefined) ||
                                        (this.props.headerNav === CONFIRMATION &&
                                            this.props.flowSource !== AuthenticatedFlow &&
                                            this.props.active === undefined &&
                                            this.props.useMyRewardsIndicator === notApplicable) ? (
                                            <>
                                                <p>
                                                    {this.props.vTranslations.DISCLAIMER_PART_ONE} {brandUrl}{' '}
                                                    {this.props.vTranslations.DISCLAIMER_PART_TWO}
                                                </p>
                                                {/* removed this paragraph as past of Credit Card info updates */}
                                                {/* <p> 
                                                    {brandText} {this.props.vTranslations.DISCLAIMER_PART_THREE}
                                                </p> */}
                                                <p>
                                                    {this.props.vTranslations.DISCLAIMER_ELIGIBILITY || "DISCLAIMER_ELIGIBILITY"}
                                                </p>
                                                 {/* removed this paragraph as past of Credit Card info updates */}
                                                {/* <p>
                                                    {this.props.vTranslations.DISCLAIMER_CARD.replace("{{brand}}", brand).replace("{{brand}}", brand.toLowerCase()) || "DISCLAIMER_CARD"}
                                                </p> */}
                                            </>
                                        ) : null}
                                    </>
                                ) : (
                                    <>
                                        {this.props.isBannerDisplayed ||
                                        this.props.useMyRewardsIndicator !== notApplicable ? (
                                            <p>
                                                {this.props.vTranslations.DISCLAIMER_PART_ONE} {brandUrl}{' '}
                                                {this.props.vTranslations.DISCLAIMER_PART_TWO}
                                            </p>
                                        ) : null}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    countryOptions: state.countryOptionsReducer.countryOptions,
    brands: state.prefillReducer.dealerSummaryObject.dealershipSummary.brands,
    vTranslations: state.countryOptionsReducer.translations,
    active: state.myRewardsReducer.active,
    enrollRewards: state.myRewardsReducer.enrollRewards,
    enrollMyRewardsError: state.myRewardsReducer.enrollMyRewardsError,
    softEnroll: state.myRewardsReducer.softEnroll,
    preferredBrand: state.myRewardsReducer.preferredBrand,
    themeMake: state.prefillReducer.themeMake,
    make: state.prefillReducer.make,
    flowSource: state.prefillReducer.flowSource,
    headerNav: state.headReducer.headerNavigation,
    showErrorMessage: state.myRewardsReducer.showError,
    useMyRewardsIndicator: state.prefillReducer.useMyRewardsIndicator,
    usePoints: state.myRewardsReducer.usePoints,
    isBannerDisplayed: state.myRewardsReducer.isBannerDisplayed,
    country: state.prefillReducer.country,
});

export default connect(mapStateToProps)(Disclaimer);
